.gsibtn {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-appearance: none;
  background-color: white;
  background-image: none;
  border: 1px solid #747775;
  border-radius: 4px;
  border-color: rgb(251 146 60 / var(--tw-border-opacity, 1));
  box-sizing: border-box;
  color: rgb(251 146 60 / var(--tw-border-opacity, 1));
  cursor: pointer;
  font-family: 'Roboto', arial, sans-serif;
  font-size: 14px;
  height: 40px;
  letter-spacing: 0.25px;
  outline: none;
  overflow: hidden;
  padding: 0 12px;
  position: relative;
  text-align: center;
  transition:
    background-color 0.218s,
    border-color 0.218s,
    box-shadow 0.218s;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;
  max-width: 400px;
  min-width: min-content;
}

.gsibtn-sm {
  border-radius: 3px;
  font-size: 10.5px;
  height: 30px;
  letter-spacing: 0.1875px;
  padding: 0 9px;
  max-width: 300px;
}

.gsibtn-lg {
  border-radius: 5px;
  font-size: 17.5px;
  height: 50px;
  letter-spacing: 0.3125px;
  padding: 0 15px;
  max-width: 500px;
}

/* Dark theme */
.gsibtn-dark {
  background-color: #131314;
  color: #e3e3e3;
  border-color: #8e918f;
}

/* Neutral theme */
.gsibtn-neutral {
  background-color: #f2f2f2;
  border: none;
}

.gsibtn-pill {
  border-radius: 20px;
}

.gsibtn-sm.gsibtn-pill {
  border-radius: 15px;
}

.gsibtn-lg.gsibtn-pill {
  border-radius: 25px;
}

/* Icon only, no text */
.gsibtn-notext {
  padding: 0;
  width: 40px;
}

.gsibtn-sm.gsibtn-notext {
  width: 30px;
}

.gsibtn-lg.gsibtn-notext {
  width: 50px;
}

.gsibtn .gsibtn-icon {
  height: 20px;
  margin-right: 12px;
  min-width: 20px;
  width: 20px;
  box-sizing: content-box;
}

.gsibtn-sm .gsibtn-icon {
  height: 15px;
  margin-right: 9px;
  min-width: 15px;
  width: 15px;
}

.gsibtn-lg .gsibtn-icon {
  height: 25px;
  margin-right: 15px;
  min-width: 25px;
  width: 25px;
}

.gsibtn-notext .gsibtn-icon {
  margin: 0;
  padding: 9px;
}

.gsibtn-sm.gsibtn-notext .gsibtn-icon {
  padding: 6.75px;
}

.gsibtn-lg.gsibtn-notext .gsibtn-icon {
  padding: 11.25px;
}

.gsibtn .gsibtn-content-wrapper {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.gsibtn-logo-center .gsibtn-content-wrapper {
  justify-content: center;
}

.gsibtn .gsibtn-contents {
  flex-grow: 1;
  font-family: 'Roboto', arial, sans-serif;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}

.gsibtn-logo-center .gsibtn-contents {
  flex-grow: 0;
}

.gsibtn .gsibtn-state {
  transition: opacity 0.218s;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.gsibtn:disabled {
  cursor: default;
  background-color: #ffffff61;
  border-color: #1f1f1f1f;
}

.gsibtn-neutral:disabled {
  border-color: none;
}

.gsibtn-dark:disabled {
  background-color: #13131461;
  border-color: #8e918f1f;
}

.gsibtn-neutral:disable .gsibtn-state {
  background-color: #1f1f1f1f;
}

.gsibtn-dark:disabled .gsibtn-state {
  background-color: #e3e3e31f;
}

.gsibtn:disabled .gsibtn-contents {
  opacity: 38%;
}

.gsibtn:disabled .gsibtn-icon {
  opacity: 38%;
}

.gsibtn:not(:disabled):active .gsibtn-state,
.gsibtn:not(:disabled):focus .gsibtn-state {
  background-color: #303030;
  opacity: 12%;
}

.gsibtn-neutral:not(:disabled):active .gsibtn-state,
.gsibtn-neutral:not(:disabled):focus .gsibtn-state {
  background-color: #001d35;
}

.gsibtn-dark:not(:disabled):active .gsibtn-state,
.gsibtn-dark:not(:disabled):focus .gsibtn-state {
  background-color: white;
}

/* .gsibtn:not(:disabled):hover {
  box-shadow:
    0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
} */

.gsibtn:not(:disabled):hover .gsibtn-state {
  background-color: rgb(251 146 60);
  opacity: 10%;
}

.gsibtn-neutral:not(:disabled):hover .gsibtn-state {
  background-color: #001d35;
}

.gsibtn-dark:not(:disabled):hover .gsibtn-state {
  background-color: white;
}

.gsibtn-d-none {
  display: none;
}
